<template>
    <div class="container pb-5">
        
        <div class="section-heading text-center mb-5">
            <h1 class="mb-2 fw-bold">COIN Friends</h1>
        </div>
        <div>
            <ul class="list">
                <li v-for="(item,index) in List1" :key="index">
                    <div class="d-flex">
                        <img :src="require('@/assets/img/'+item.img)" :alt="item.title">
                    </div>
                </li>
            </ul>
        </div>
        <div class="section-heading text-center mt-5 mb-5 pt-5">
            <h1 class="mb-2 fw-bold">Media</h1>
        </div>
        <div>
            <ul class="list">
                <li v-for="(item,index) in List2" :key="index">
                    <div class="d-flex">
                        <img :src="require('@/assets/img/'+item.img)" :alt="item.title">
                    </div>
                </li>
            </ul>
        </div>
        <div class="section-heading text-center mt-5 mb-5 pt-5">
            <h1 class="mb-2 fw-bold">Community</h1>
        </div>
        <div>
            <ul class="list">
                <li v-for="(item,index) in List3" :key="index">
                    <div class="d-flex">
                        <img :src="require('@/assets/img/'+item.img)" :alt="item.title">
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            List1: [
                {
                    title: "some name",
                    img: "friends_sample.png"
                },
                {
                    title: "some name",
                    img: "friends_sample.png"
                },
                {
                    title: "some name",
                    img: "friends_sample.png"
                },
                {
                    title: "some name",
                    img: "friends_sample.png"
                }
            ],
            List2: [
                {
                    title: "some name",
                    img: "friends_sample.png"
                },
                {
                    title: "some name",
                    img: "friends_sample.png"
                }
            ],
            List3: [
                {
                    title: "some name",
                    img: "friends_sample.png"
                },
                {
                    title: "some name",
                    img: "friends_sample.png"
                },
                {
                    title: "some name",
                    img: "friends_sample.png"
                },
                {
                    title: "some name",
                    img: "friends_sample.png"
                },
                {
                    title: "some name",
                    img: "friends_sample.png"
                },
                {
                    title: "some name",
                    img: "friends_sample.png"
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.list{
    display: flex;
    flex-wrap: wrap;

    gap: 2%;

    li{
        width: 23%;
        margin-bottom: 2%;
        text-align: center;
        border-radius: 10px;
        box-shadow: 0 4px 15px rgba($color: #000, $alpha: 0.1);
        transition: all 0.2s;
        background-color: #fff;
        padding: 16px;

        .d-flex{
            width: 100%;
            justify-content: center;
        }

        img{
            height: 40px;
            margin-right: 20px;
        }

        &:hover{
            transform: translateY(-5px);
        }
    }
}

@media screen and (max-width:768px) {
.list{

    li{
        width: 49%;
        margin-bottom: 2%;
    }
}

}
</style>